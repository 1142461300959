import { smsApi } from "@/api/sms.js";
export default {
  name: "Sms",
  components: {},
  data: () => ({
    listSms: [],
    isLoading: false,
  }),
  async created() {
    await this.getListSms();
  },
  methods: {
    async getListSms() {
      const loading = this.$vs.loading();
      this.isLoading = true;
      let body = {};
      await smsApi.getListSms(body).then((res) => {
        this.listSms = res;
      });
      this.isLoading = false;
      loading.close();
    },
  },
};

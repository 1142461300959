import { ROOT, GET_LIST_SMS } from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function getListSms(data) {
  return fetch(ROOT + GET_LIST_SMS, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const smsApi = {
  getListSms,
};
